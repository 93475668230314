import { userConstants, boardConstants } from '@constants/appuser';
import { correctUserType } from '@lib/simpletools'
import moment from 'moment';
import { LocalStorageVariableNames } from '../../constants';
import { SmartlookWrapper } from '../../lib/smartlookFunctions';

let userCache = JSON.parse(localStorage.getItem(window.athenaAppID));

const initialState = userCache ? Object.assign({ loggedIn: true, askforCode: false, passwordPolicy: {} }, userCache) : {askforCode: false, loggedIn: false };

function getInitial(){
  return {
    sessionToken: '',
    boardId: '',
    customerId: '',
    customerIds: '',
    userId: '',
    userIds: '',
    username: '',
    firstName: '',
    lastName: '',
    imageId: '',
    lastLoginDate: moment(''),
    companyName: '',
    companyImageId: '',
    deviceId: '',
    lockScreen: true,
    updatePassword: false,
    displaySettings: {},
    customerSettings: {},
    userType: {},
    viewAs: '',
  }
}

function updateCookie(state){
  var userCache = JSON.parse(localStorage.getItem(window.athenaAppID))
  if(userCache !== null){
    userCache.sessionToken = state.sessionToken
    userCache.customerId = state.customerId
    userCache.customerIds = state.customerIds
    userCache.userId = state.userId
    userCache.userIds = state.userIds
    userCache.username = state.username
    userCache.firstName = state.firstName
    userCache.lastName = state.lastName
    userCache.imageId = state.imageId
    userCache.companyName = state.companyName
    userCache.customers = state.customers
    userCache.companyImageId = state.companyImageId
    userCache.deviceId = state.deviceId
    userCache.updatePassword = state.updatePassword
    userCache.lockScreen = state.lockScreen
    userCache.displaySettings = state.displaySettings
    userCache.customerSettings = state.customerSettings
    userCache.userType = state.userType
    localStorage.setItem(window.athenaAppID, JSON.stringify(userCache))

    localStorage.setItem('offline', JSON.stringify({
      customerId: state.customerId
    }))
  }
}

function getUserId(user){
  if(user.length !== 0){
    return user[0];
  }
  return "";
}

function FailedCount(state){
  if(state.failed === undefined) return 0;
  return state.failed+1;
}

function setGenPrivKey(state, item){
  if(state.keys === undefined)
    state.keys = {};
  if(state.keys[item.customerId] === undefined)
    state.keys[item.customerId] = {};

  state.keys[item.customerId].pUserGenSec = item.kUser;

  return state;
}

function setGenKey(state, item){
  if(state.keys === undefined)
    state.keys = {};
  if(state.keys[item.customerId] === undefined)
    state.keys[item.customerId] = {};

  state.keys[item.customerId].kUserGenSec = item.kUser;

  return state;
}

function setUsers(state, items){
  if(items === undefined || items.id === undefined || items.id === "") return state
  if(state.imageId === undefined)
    state.imageId = ""

  if(state.customerOpt === undefined)
    state.customerOpt = {}
  if(state.customerOpt[items.id] === undefined)
    state.customerOpt[items.id] = {}

  if(items.passwordPolicy !== undefined){
    state.customerOpt[items.id].passwordPolicyId = items.passwordPolicy.id
    if(state.passwordPolicy === undefined)
      state.passwordPolicy = {}
    state.passwordPolicy[items.passwordPolicy.id] = items.passwordPolicy
  }

  if(items.passwordPolicyId !== undefined && state.customerOpt[items.id].passwordPolicyId !== undefined){
    if(state.customerOpt[items.id].passwordPolicyId !== items.passwordPolicyId)
      state.customerOpt[items.id].passwordPolicyId = undefined
  }

  if(items.passwordPolicyDescription !== undefined)
    state.customerOpt[items.id].passwordPolicyDescription = items.passwordPolicyDescription
  else state.customerOpt[items.id].passwordPolicyDescription = ""
  if(items.passwordPolicyRegex !== undefined)
    state.customerOpt[items.id].passwordPolicyRegex = items.passwordPolicyRegex
  else state.customerOpt[items.id].passwordPolicyRegex = ""
  if(items.requiresRecoveryCard !== undefined)
    state.customerOpt[items.id].requiresRecoveryCard = items.requiresRecoveryCard
  else state.customerOpt[items.id].requiresRecoveryCard = true

  //Correct the right userId
  if(items.id === state.customerId){
    state.companyName = items.companyName
    /*if(items.adminUsers !== undefined && state.userIds !== undefined){
      state.userIds.some(function(o){
        var f = items.adminUsers.find(i => i.id === o)
        if(f){
          state.userId = f.id
          return true
        }
        return false
      })
    }*/
  }

  if(items.users !== undefined && state.userId !== undefined){
    var f = items.users.find(u => u.id === state.userId)
    if(f){
      state.imageId = f.imageId
      state.customerOpt[items.id].imageId = f.imageId
    }
  }

  updateCookie(state)

  return state
}

function switchCustomer(state, customerId){
  state.customerId = customerId
  state.companyName = ""
  if(state.customers !== undefined){
    var c = state.customers.find(o => o.id === customerId)
    if(c){
      state.companyName = c.companyName || c.name
      state.userId = c.userId
    }
  }

  state.companyImageId = ""
  if(state.customerSettings !== undefined)
    if(state.customerSettings[customerId] !== undefined)
      if(state.customerSettings[customerId].logoImageId !== '' && state.customerSettings[customerId].logoImageId !== undefined)
        state.companyImageId = state.customerSettings[customerId].logoImageId

  updateCookie(state)

  return state;
}

function setLogin(state, payload){
  var customerIds = [], customerName = '', customerId = '', customers = []
  var userId = ''

  let lastCache = JSON.parse(localStorage.getItem('offline'));
  if(lastCache === null) lastCache = JSON.parse(localStorage.getItem('offline'));
  var userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
  if(userCache === null) userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
  if(state.lcustomerId !== undefined && state.lcustomerId !== ""){
    customerId = state.lcustomerId
    //    state = {}
  }else if(state.customerId !== undefined && state.customerId !== ""){
    customerId = state.customerId
  }

  if(payload.customers !== undefined && customerId !== ""){
    var f = payload.customers.find(o => o.id === customerId)

    if(f){
      customerName = f.companyName || f.name
      customerId = f.id
      userId = f.userId
    }
  }

  var customerDetails = null;
  if(payload.customers !== undefined) customerDetails = payload.customers.find(o => o.id === customerId)

  let d = ""
  if(payload.lastLoginDate !== undefined && payload.lastLoginDate !== "") d = payload.lastLoginDate

  var r = {
    sessionToken: payload.sessionToken,
    customerId: customerId,
    certificateId: payload.person.certificateId,
    certificateExpiryDate: payload.person.certificateExpiryDate,
    boardId: state.boardId !== undefined && state.boardId !== ""?state.boardId:'',
    userId: userId,
    pdLic: payload.pdLic,
    pdLicWrap: payload.pdLicWrap,
    userIds: payload.userIds,
    alias: payload.alias,
    username: payload.username,
    firstName: payload.person.firstName,
    lastName: payload.person.lastName,
    personId: payload.person.id,
    imageId: '',
    companyName: customerName,
    customers: payload.customers,
    companyImageId: '',
    deviceId: payload.deviceId,
    deviceHash: payload.deviceHash,
    updatePassword: payload.updatePassword,
    lockScreen: false,
    lastLoginDate: d,
//    displaySettings: {},
//    customerSettings: {},
//    passwordPolicy: {},
    userType: payload.userType,
    webSocket: null,
    mode: payload.mode,
    suspendedCustomers: payload.suspendedCustomers !== undefined?payload.suspendedCustomers:[],
    universalLogin: payload.universalLogin,
    universalRedirect: payload.universalRedirect,
    country: payload.country,
    secondsForInAppTimeout: state.secondsForInAppTimeout !== undefined? state.secondsForInAppTimeout : 3 * 60,
    viewAs: '',
    type: 'app',
  }
  if(userCache){
    r.imageId = userCache.imageId
    r.companyImageId = userCache.companyImageId
  }
  localStorage.setItem(window.athenaAppID, JSON.stringify(r))

  r.lcustomerId = undefined
  //r.lastLoginDate = moment(d)

  try {
    var devices = JSON.parse(localStorage.getItem(LocalStorageVariableNames.BoardLoginDevices));
    if (devices) {
      r.devices = devices;
      localStorage.removeItem(LocalStorageVariableNames.BoardLoginDevices);
    }
  } catch { }

  if(payload.sms === true){
    r.mfaType = "sms"
  }

  let userRegistrationDate = moment().format()
  if(payload.customers !== undefined){
    payload.customers.forEach((c)=>{
      if(moment(userRegistrationDate).isAfter(moment(c.userRegistrationDate)))
        userRegistrationDate = c.userRegistrationDate
    })
  }
  var userType = ""
  if(payload.userType !== undefined && payload.userType[customerId] !== undefined){
    userType = payload.userType[customerId]
  }else if(payload.userType !== undefined){
    userType = payload.userType[Object.keys(payload.userType)[0]];
  }
  userType = correctUserType(userType);

  // if(payload.person.id !== undefined && appConfig.segment){
  //   if (typeof analytics != 'undefined') {
  //     analytics.identify(payload.person.id, {
  //       name: payload.person.firstName+" "+payload.person.lastName,
  //       email: payload.alias,
  //       //plan: "board.premium",
        
  //       personId: payload.person.id,
  //       boardPortalType: 'director',
  //       userType: userType,
  //       registrationDate: new Date(userRegistrationDate).toISOString(),
  //       loginCount: payload.loginCount!==undefined?payload.loginCount:-1,
  //       //userId: <-- Ansarada user Id
  //     });
  //   }
  // }

  if (payload.person.id !== undefined) {
    SmartlookWrapper('identify', {
      personId: payload.person.id,
      name: payload.person.firstName + " " + payload.person.lastName,
      email: payload.alias,
      //plan: "board.premium",
      
      boardPortalType: 'director',
      userType: userType,
      registrationDate: new Date(userRegistrationDate),
      loginCount: payload.loginCount !== undefined ? payload.loginCount : -1,
      //userId: <-- Ansarada user Id
    });
  }

  if(payload.maintenance !== undefined){
    r.maintenance = payload.maintenance
  }


  if(payload.passwordPolicy !== undefined && payload.passwordPolicy.id !== undefined && payload.passwordPolicy.id !== ""){
    if(r.passwordPolicy === undefined) r.passwordPolicy = {}
    r.passwordPolicy[payload.passwordPolicy.id] = payload.passwordPolicy
    r.passwordPolicyId = payload.passwordPolicy.id
  }
  if(payload.passwordPolicyDescription !== undefined){
    r.passwordPolicyDescription = payload.passwordPolicyDescription
    r.passwordPolicyRegex = payload.passwordPolicyRegex
  }else{
    r.passwordPolicyDescription = "";
    r.passwordPolicyRegex = "";
  }
  /*if(payload.requiresRecoveryCard !== undefined){
    r.customerOpt[customerId].requiresRecoveryCard = payload.requiresRecoveryCard
  }else{
    r.customerOpt[customerId].requiresRecoveryCard = true
  }*/
  if(payload.pendingInvites !== undefined){
    r.pendingInvites = payload.pendingInvites
  }
  if(payload.confirmInvite !== undefined){
    r.confirmInvite = payload.confirmInvite
  }
  if(payload.webSocket !== undefined){
    r.webSocket = payload.webSocket
  }

  var keys = payload.keys
  r.loggedIn = true
  if(r.keys === undefined){
    r.keys = keys
  }else{
    for(var key in keys){
      Object.keys(keys[key]).map(e => r.keys[key][e] = keys[key][e])
    }
  }

  return Object.assign({}, state, r);
}

function GetUniversal(state){
  let r = {}
  if(state.alias !== undefined) r.alias = state.alias
  if(state.mode !== undefined) r.mode = state.mode
  if(state.universalLogin !== undefined) r.universalLogin = state.universalLogin
  if(state.username !== undefined) r.username = state.username
  if(state.universalRedirect !== undefined) r.universalRedirect = state.universalRedirect
  if(state.requiresPassword !== undefined) r.requiresPassword = state.requiresPassword
  if(state.failed !== undefined) r.failed = state.failed
  if(state.deviceHash !== undefined) r.deviceHash = state.deviceHash
  if(state.deviceId !== undefined) r.deviceId = state.deviceId
  if(state.sms !== undefined) r.sms = state.sms
  if(state.boardId !== undefined) r.boardId = state.boardId
  return r
}

export function authentication(state = initialState, action) {
  let r = {};
  switch (action.type) {
    case userConstants.LOGIN_CHECK:
      r = {
        userChecking: true,
        user: action.username,
        failed: state.failed,
        lockScreen: false,
      }
      return r;
    case userConstants.LOGIN_REQUEST:
      r = Object.assign(GetUniversal(state), {
        loggingIn: true,
        username: action.username,
        failed: state.failed,
        lockScreen: false,
      });
      if(state.customerId !== undefined && state.customerId !== "")
        r.lcustomerId = state.customerId;
      return r;
    case userConstants.LOGIN_LOCK_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        failed: state.failed,
        lockScreen: true,
      };
    case userConstants.LOGIN_SUCCESS:
      r = {}
      if(state.boardId !== undefined && state.boardId !== "")
        r.boardId = state.boardId;
      if(state.lcustomerId !== undefined && state.lcustomerId !== "")
        r.lcustomerId = state.lcustomerId;
      if(action.payload.lcustomerId !== undefined && action.payload.lcustomerId !== "")
        r.lcustomerId = action.payload.lcustomerId;
      return setLogin(r, action.payload)
    case userConstants.LOGIN_AUTHZERO_REQUEST:
      return Object.assign({}, state, {auth0Result: null});
    case userConstants.LOGIN_AUTHZERO_SUCCESS:
      return Object.assign({}, state, {auth0Result: action.payload});
    case userConstants.LOGIN_AUTHZERO_FAILURE:
      return Object.assign({}, state, {auth0Result: false});
    case userConstants.LOGIN_DEVICE_FAILURE:
      return {
        wrongDevice: true,
        loggedIn: false,
      };
    case userConstants.LOGIN_WAITING_APPROVAL:
      return Object.assign(GetUniversal(state), {
        pendingApproval: true,
        loggedIn: false,
      });
    case userConstants.LOGIN_CLEAR:
      r = {
        loggedIn: true,
        loading: true,
        error: "",
      };
      if(state.failed !== undefined) r.failed = state.failed
      return r
    case userConstants.LOGIN_CLEAR_ERROR:
      return Object.assign({}, state, {error: ""});
    case userConstants.LOGIN_FAILURE:
      return Object.assign(GetUniversal(state), {
        lockScreen: false,
        loggedIn: false,
        error: "Unauthorised",
        failed: FailedCount(state),
        username: state.username,
        //requiresPassword: undefined,
        //universalLogin: undefined,
        //mode: undefined,
      });
    case userConstants.LOGIN_LOCK_FAILURE:
      return {
        lockScreen: true,
        loggedIn: false,
        error: "Unauthorised",
        failed: FailedCount(state),
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.LOGOUT_REQUEST:
      return {
        loggingOut: true,
        loggedIn: false,
        loading: true,
        error: "",
      };
    case userConstants.LOGOUT_SUCCESS:
      return {
        loggingOut: true,
        loggedIn: false,
        loading: true,
        error: "",
      };
    case userConstants.LOGIN_FAILURE:
      return Object.assign(GetUniversal(state), {
        lockScreen: false,
        loggedIn: false,
        error: "Unauthorised",
        failed: FailedCount(state),
        username: state.username,
        //requiresPassword: undefined,
        //universalLogin: undefined,
        //mode: undefined,
      });
    case userConstants.LOGIN_WEBSOCKET_SUCCESS:
      return Object.assign({}, state, {webSocket: action.payload});
    case userConstants.SESSION_REPLACEMENT:
      return Object.assign({}, state, {lockScreen: true, sessionReplaced: true});
    case userConstants.REGISTER_DEVICE_REQUEST:
      return { loggingIn:true };
    case userConstants.REGISTER_DEVICE_SUCCESS:
      return { loggingIn:false };
    case userConstants.REGISTER_DEVICE_FAILURE:
      return {};
    case userConstants.REGISTER_USERANDDEVICE_REQUEST:
      return Object.assign(GetUniversal(state), { loggingIn:true, askforUserDetails: true, username: action.username });
    case userConstants.REGISTER_USERANDDEVICE_AUTHZERO:
      r = Object.assign({}, action.payload, {loggingIn:false, askforCode: true, askforUserDetails: true})
      return r;
    case userConstants.REGISTER_USERANDDEVICE_NEWZERO:
      return Object.assign({}, action.payload, {loggingIn:true, askforUserDetails: true});
    case userConstants.REGISTER_USERANDDEVICE_SUCCESS:
      var mfaId = undefined, mfaType = undefined;
      if(action.mfaData !== undefined && action.mfaData.mfaId !== undefined && action.mfaData.mfaId !== ""){
        mfaId = action.mfaData.mfaId
        if(action.mfaType !== undefined)
          mfaType = action.mfaType
      }
      return Object.assign(GetUniversal(state), { loggingIn:false, askforCode: true, askforUserDetails: true, mfaId: mfaId, mfaType: mfaType, username: action.username});
    case userConstants.REGISTER_USERANDDEVICE_FAILURE:
      return { loggingIn:false, askforUserDetails: true, username: action.username };
    case userConstants.REGISTER_USERDEVICE_REQUEST:
      r = Object.assign(GetUniversal(state), { loggingIn:true, username: action.username });
      if(action.password !== undefined)
        r.password = action.password
      return r
    case userConstants.REGISTER_USERDEVICE_SUCCESS:
      var policy = '', polDescription = '', mfaId = undefined, mfaType = undefined;
      if(action.payload !== undefined){
        if(action.payload.passwordPolicyRegex !== undefined){
          policy = action.payload.passwordPolicyRegex;
          polDescription = action.payload.passwordPolicyDescription;
        }
        if(action.payload.mfaId !== undefined && action.payload.mfaId !== ""){
          mfaId = action.payload.mfaId
          if(action.payload.sms === true)
            mfaType = "sms"
          if(action.payload.mfaType !== undefined)
            mfaType = action.payload.mfaType
        }
      }

      r = Object.assign(GetUniversal(state), {
        loggingIn:false,
        askforCode: true,
        mfaId: mfaId,
        mfaType: mfaType,
        passwordPolicyDescription: polDescription,
        passwordPolicyRegex: policy,
        username: action.payload.username,
        deviceId: action.payload.deviceId,
        alias: action.payload.alias,
        mode: action.payload.mode,
        universalLogin: action.payload.universalLogin,
        universalRedirect: action.payload.universalRedirect,
      })
      if(state.password !== undefined && state.password !== "") r.password = state.password
      return r;
    case userConstants.REGISTER_USERDEVICE_POLICY_SUCCESS:
      return { loggingIn:false, askforCode: true };
    case userConstants.REGISTER_USERDEVICE_FAILURE:
      return { loggingIn:false, askforCode: false, error: "Unauthorised" };
    case userConstants.REGISTER_USERDEVICEKEY_REQUEST:
      return Object.assign(GetUniversal(state), { loggingIn:true, askforCode: true });
    case userConstants.REGISTER_USERDEVICEKEY_SUCCESS:
      return Object.assign(GetUniversal(state), { loggingIn:false, askforCard: true });
    case userConstants.REGISTER_USERDEVICEKEY_FAILURE:
      return Object.assign(GetUniversal(state), { loggingIn:false, askforCode: true , error: 'Incorrect Code'});
    case userConstants.CLEAR_AUTH_CODE_REQUEST:
      return { loggingIn:false };
    case userConstants.FORGOT_NOCODE_LOGIN_REQUEST:
      return { loggingIn:false, askforLogin: true };
    case userConstants.FORGOT_NOCODE_NOTFY_REQUEST:
      return Object.assign({}, state, { loggingIn:true});
    case userConstants.FORGOT_NOCODE_NOTFY_SUCCESS:
      var j = {loggingIn: false, askforCode: true}
      if(action.mfaId !== undefined) j.mfaId = action.mfaId
      return Object.assign({}, state, j);
    case userConstants.FORGOT_NOCODE_NOTFY_FAILURE:
      return Object.assign({}, state, {loggingIn:false, askforLogin: true, error: action.error});
/*    case userConstants.FORGOT_NOCODE_CODE_REQUEST:
      return Object.assign({}, state, {loggingIn:true, error: ""});
    case userConstants.FORGOT_NOCODE_CODE_SUCCESS:
      return Object.assign({}, state, {
        loggingIn:false,
        askforCode: undefined,
        loggedIn: undefined,
        resetKey: {
          resetToken: action.item.sessionToken,
          secretariatKeys: action.item.secretariatKeys,
          kPlatform: action.item.kPlatform,
        },
        askforPassword: true,
      });
    case userConstants.FORGOT_NOCODE_CODE_FAILURE:
      return Object.assign({}, state, {loggingIn:false, error: "Unauthorised", askforCode: true});*/
    case userConstants.FORGOT_NOCODE_NEWPASS_REQUEST:
      return Object.assign({}, state, {loggingIn:true, askforPassword: undefined, askforCode: undefined, resetKey: undefined});
    case userConstants.FORGOT_NOCODE_NEWPASS_SUCCESS:
      return Object.assign({}, state, {loggingIn:false, resetComplete: true, askforCode: undefined, loggedIn: undefined})
    case userConstants.FORGOT_NOCODE_NEWPASS_FAILURE:
      return Object.assign({}, state, {loggingIn:false, error: "Unauthorised"});

    case userConstants.FORGOT_CARD_NOTFY_REQUEST:
      return Object.assign({}, state, {loggingIn:true})
    case userConstants.FORGOT_CARD_NOTFY_SUCCESS:
      return Object.assign({}, state, {loggingIn:false, resetKey: action.item, askforPassword: true, loggedIn: undefined, askforCode: undefined})
    case userConstants.FORGOT_CARD_NOTFY_FAILURE:
      return Object.assign({}, state, {loggingIn:false, error: action.error, askforCode: undefined, loggedIn: undefined})
    case userConstants.FORGOT_CARD_NEWPASS_REQUEST:
      return Object.assign({}, state, {loggingIn:true})
    case userConstants.FORGOT_CARD_NEWPASS_SUCCESS:
      return Object.assign({}, state, {loggingIn:false, resetComplete: true, askforPassword: undefined, askforCode: undefined, loggedIn: undefined})
    case userConstants.FORGOT_CARD_NEWPASS_FAILURE:
      return Object.assign({}, state, {loggingIn:false, error: action.error, askforPassword: undefined, askforCode: undefined, loggedIn: undefined})

    case userConstants.AUTHZERO_RESET_REQUEST:
      return Object.assign({}, state, {loggingIn:true})
    case userConstants.AUTHZERO_RESET_SUCCESS:
      return Object.assign({}, GetUniversal(state), {loggingIn:false, sendResetEmail: true})
    case userConstants.AUTHZERO_RESET_FAILURE:
      return Object.assign({}, GetUniversal(state), {loggingIn:false, error: action.error})

    case userConstants.HAS_DEVICE_NOTFY_REQUEST:
      return Object.assign({}, state, {loggingIn:true, error: ""});
    case userConstants.HAS_DEVICE_CHECKED_SUCCESS:
      r = Object.assign({}, action.payload, {userChecked: true});
      if (r.userType !== "User"){
        r.loggingIn = true
      }else{
        if(r.mode === 2 && !r.requiresPassword && !r.universalLogin && !r.hasDevice) r.askforUserDetails = false
        else if(!r.hasDevice && !r.universalLogin){
          r.askforUserDetails = true
        }
      }
      if(state.failed !== undefined) r.failed = state.failed
      if(action.payload.enabled === false){
        r.maintenance = {
          enabled: action.payload.enabled,
          statusMessage: action.payload.statusMessage,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime
        }
      }
      //if(r.keys === false) r.hasDevice = false
      return r;
    case userConstants.HAS_DEVICE_NOTFY_SUCCESS:
      r = Object.assign({
        loggingIn:false,
        askforUserDetails: !action.payload.hasDevice,
      }, action.payload)

      if(action.payload.enabled === false){
        r.maintenance = {
          enabled: action.payload.enabled,
          statusMessage: action.payload.statusMessage,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime
        }
      }

      // if(action.payload.passwordPolicyRegex !== undefined){
      //   j.passwordPolicyRegex = action.payload.passwordPolicyRegex;
      //   j.passwordPolicyDescription = action.payload.passwordPolicyDescription;
      // }
      // if(action.payload.canResetPasssword !== undefined){
      //   j.canResetPasssword = action.payload.canResetPasssword;
      //   j.canResetPasswordWithAdmin = action.payload.canResetPasswordWithAdmin;
      // }

      return r;
    case userConstants.HAS_DEVICE_NOTFY_FAILURE:
      return {loggingIn:false, error: action.error};

    case userConstants.REGISTER_NEW_USER_REQUEST:
      return Object.assign(GetUniversal(state), {loggingIn:true, askforUserDetails: true, username: action.username});
    case userConstants.REGISTER_NEW_USER_SUCCESS:
      return Object.assign(GetUniversal(state), {loggingIn:false, askforUserDetails: true, completeDetails: true, username: action.username});
    case userConstants.REGISTER_NEW_USER_CLEAR:
      return {loggingIn:false};
    case userConstants.REGISTER_NEW_USER_CCOMPLETED:
      return Object.assign(GetUniversal(state), {loggingIn:false, error: "", askforUserDetails: false});
    case userConstants.REGISTER_NEW_USER_FAILURE:
      return Object.assign(GetUniversal(state), {loggingIn:false, error: action.error, askforUserDetails: true, username: action.username});

    case userConstants.GETALL_SUCCESS:
    /*case userConstants.POPULATEALL_USERS_SUCCESS:
      return Object.assign({}, setUsers(state, action.items), {latestAction: new Date()});*/
    case userConstants.KEEP_SESSION_ALIVE:
      return Object.assign({}, state, {});
    case userConstants.ACTIVE_SESSION_ALIVE:
      userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(userCache){
        userCache.lockScreen = false;
      }else{
        userCache = getInitial();
      }
      localStorage.setItem(window.athenaAppID, JSON.stringify(userCache));
      return Object.assign({}, state, {lockScreen: false, latestAction: new Date()});
    case userConstants.KILL_SESSION_ALIVE:
      userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(userCache){
        userCache.lockScreen = true;
      }else{
        userCache = getInitial();
      }
      localStorage.setItem(window.athenaAppID, JSON.stringify(userCache));
      return Object.assign({}, state, {lockScreen: true, latestAction: new Date()});

    case userConstants.CHANGE_PASSWORD_CLEAR:
      userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(userCache){
        userCache.updatePassword = false;
      }else{
        userCache = getInitial();
      }
      localStorage.setItem(window.athenaAppID, JSON.stringify(userCache));
      return Object.assign({}, state, {updatePassword: false, latestAction: new Date()});

    case userConstants.CLEAR_ERROR_MSG:
      return Object.assign({}, state, {error: ''});

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({ 
        loggingOut: true,
      });

    case userConstants.INTERNET_LOST:
      return Object.assign({}, state, {internet: false, latestAction: new Date()});

    case userConstants.WEBSITE_UPDATE_AVAILABLE:
      return Object.assign({}, state, {updateWebPage: true, latestAction: new Date()});

    case userConstants.USER_DISPLAYSETTINGS_SUCESS:
      return Object.assign({}, updateSettings(state, action.key, action.value), {latestAction: new Date()});

    case userConstants.GET_PASSWORDPOLICY_SUCCESS:
      r = Object.assign({}, state)
      if(action.payload.passwordPolicy !== undefined){
        r.passwordPolicyId = action.payload.passwordPolicy.id
        if(r.passwordPolicy === undefined)
          r.passwordPolicy = {}
        r.passwordPolicy[action.payload.passwordPolicy.id] = action.payload.passwordPolicy
      }else if(action.payload.passwordPolicyRegex !== undefined){
        r.passwordPolicyRegex = action.payload.passwordPolicyRegex;
        r.passwordPolicyDescription = action.payload.passwordPolicyDescription;
      }

      return r;
    /*case userConstants.GET_GENERIC_KEY_SUCCESS:
      return Object.assign({}, setGenKey(state, action.payload), {latestAction: new Date()});
    case userConstants.GET_PGENERIC_KEY_REQUEST:
      return Object.assign({}, setGenPrivKey(state, action.payload), {latestAction: new Date()});
    case userConstants.GET_PGENERIC_KEY_SUCCESS:
      return Object.assign({}, setGenPrivKey(state, action.payload), {latestAction: new Date()});*/

    case userConstants.MANUAL_CONFIRM_INVITE_REQUEST:
      return Object.assign({}, state, {mfaId: undefined});

    case userConstants.AUTO_INVITE_SUCCESS:
      return Object.assign({}, state, { completedInvites: action.list,  });
    case userConstants.CLEAR_CONFIRM_INVITE_SUCESS:
      return Object.assign({}, state, { confirmInvite: false, completedInvites: undefined, mfaId: undefined, });

    case userConstants.AUTHCODE_CONFIRM_INVITE_SUCCESS:
      var mfaId = undefined
      if(action.mfaId !== undefined && action.mfaId !== ""){
        mfaId = action.mfaId
      }
      return Object.assign({}, state, {mfaId: mfaId});

    case userConstants.MAINLOGIN_CLEAR_REQUEST:
      return Object.assign({}, state, {mainLogin: undefined});

    case userConstants.SET_KEYS:
      return Object.assign({}, state, {keys: action.keys});

    case userConstants.GET_IMPERSONATE_REQUEST:
      userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(userCache){
        userCache.viewAs = action.userId;
      }else{
        userCache = getInitial();
      }
      localStorage.setItem(window.athenaAppID, JSON.stringify(userCache));
      return Object.assign({}, state, { viewAs: action.userId });

    case userConstants.GET_IMPERSONATE_SUCCESS:
      state.keys[action.payload.userId] = {
        kUser: action.payload.kUser
      }
      return Object.assign({}, state);

    case userConstants.SET_TIMEOUT:
      userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(userCache){
        userCache.secondsForInAppTimeout = action.secondsForInAppTimeout;
      }else{
        userCache = getInitial();
      }
      localStorage.setItem(window.athenaAppID, JSON.stringify(userCache));
      return Object.assign({}, state, {secondsForInAppTimeout: action.secondsForInAppTimeout});
    case userConstants.SWITCH_CUSTOMER:
      userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(userCache){
        userCache.boardId = action.boardId;
        userCache.customerId = action.customerId;
        if(action.secondsForInAppTimeout !== undefined)
          userCache.secondsForInAppTimeout = action.secondsForInAppTimeout;
      }else{
        userCache = getInitial();
      }
      localStorage.setItem(window.athenaAppID, JSON.stringify(userCache));
      return Object.assign({}, state, {boardId: action.boardId, customerId: action.customerId, secondsForInAppTimeout: action.secondsForInAppTimeout});

      case userConstants.TRAIL_CHECKEMAIL_REQUEST:
        return Object.assign({}, {loggedIn: false, checkEmail: true, error: ""});
      case userConstants.TRAIL_CHECKEMAIL_SUCCESS:
        return Object.assign({}, {username: action.payload.username, existingUser: action.payload.existingUser, clientId: action.payload.clientId, checkEmail: false, usesMFA: action.payload.usesMFA , usesSSO: action.payload.usesSSO });
      case userConstants.TRAIL_CHECKEMAIL_FAILURE:
        return Object.assign({}, {loggedIn: false, loading: false, error: action.error, checkEmail: false});
      case userConstants.TRAIL_CHECKEMAIL_DELETE:
        return Object.assign({}, { error: "" });
  
      case userConstants.TRAIL_COMPLETE_REQUEST:
        return Object.assign({}, {loggingIn: true, loggedIn: true, error: ""});
      case userConstants.TRAIL_COMPLETE_SUCCESS:
        return Object.assign({}, {loggingIn: false, username: action.payload.username, existingUser: action.payload.existingUser });
      case userConstants.TRAIL_COMPLETE_FAILURE:
        return Object.assign({}, {loggingIn: false, loggedIn: false, loading: false, error: action.error});

      case userConstants.TRAIL_COSECSAVE_REQUEST:
        return Object.assign({}, state, { cosecLoading: true, error: undefined });
      case userConstants.TRAIL_COSECSAVE_SUCCESS:
        return Object.assign({}, state, { cosecLoading: false, error: "" });
      case userConstants.TRAIL_COSECSAVE_FAILURE:
        return Object.assign({}, state, { cosecLoading: false, error: action.error });
  
      case userConstants.TRAIL_GETNEWUSERINFO_REQUEST:
        return Object.assign({}, {loadingInfo: true});
      case userConstants.TRAIL_GETNEWUSERINFO_SUCCESS:
        return Object.assign({}, {clientId: action.payload.clientId, username: action.payload.email, passwordPolicy: action.payload.passwordPolicy, existingUser: action.payload.ansaradaProfileAlreadyExists, loadingInfo: false, usesSSO: action.payload.usesSSO || false });
      case userConstants.TRAIL_GETNEWUSERINFO_FAILURE:
        return Object.assign({}, {error: action.error, loadingInfo: false, loadingInfoError: action.error});
  
      case userConstants.TRAIL_EXISTINGCOMPLETE_REQUEST:
        return Object.assign({}, state, { loggingIn: true, loggedIn: false, loading: true, error: "" });
      case userConstants.TRAIL_EXISTINGCOMPLETE_SUCCESS:
        var mfaId = undefined, mfaType = undefined;
        if(action.mfaId !== undefined && action.mfaId !== ""){
          mfaId = action.mfaId
          if(action.sms === true)
            mfaType = "sms"
        }
        return Object.assign({}, state, { loggingIn: false, loggedIn: true, loading: false, askforCode: true, mfaId: mfaId, mfaType: mfaType, athenaUsername: action.user.username, registrationCode: action.user.registrationCode, deviceId: action.user.deviceId });
      case userConstants.TRAIL_EXISTINGCOMPLETE_FAILURE:
        return Object.assign({}, state, { loggingIn: false, loggedIn: false, loading: false, error: action.error, username: action.username });
  
      case userConstants.TRAIL_EXISTINGREGISTER_REQUEST:
        return Object.assign({}, state, { loggingIn: true, loading: true, askforCode: false });
      case userConstants.TRAIL_EXISTINGREGISTER_SUCCESS:
        return Object.assign({}, state, { loggingIn: true, loading: false });
      case userConstants.TRAIL_EXISTINGREGISTER_FAILURE:
        return Object.assign({}, state, { loggingIn: false, loading: false, askforCode: true });
  
      case userConstants.LOGIN_AUTHZERO_REQUEST:
        return Object.assign({}, state, {loggingIn: true, error: ""});
      case userConstants.LOGIN_AUTHZERO_SUCCESS:
        return Object.assign({}, state, {auth0Result: action.payload});
      case userConstants.LOGIN_AUTHZERO_FAILURE:
        return Object.assign({}, state, {auth0Result: false, error: action.error});
          
    default:
      return state
  }
}
