import { documentAnnotationsConstants } from "../../constants/appuser";

var INITIAL_STATE = {
    sharedAnnotations: {}
}

function setSharedAnnotationForDocumentId(state, documentId, payload) {
    var newState = { ...state };
    if (!newState.sharedAnnotations) {
        newState.sharedAnnotations = {};
    }
    newState.sharedAnnotations[documentId] = payload;
    return newState;
}

function clearSharedAnnotationForDocumentId(state, documentId) {
    var newState = { ...state };
    if (!newState.sharedAnnotations) {
        newState.sharedAnnotations = {};
    }
    try {
        delete newState.sharedAnnotations[documentId];
    } catch { }
    return newState;
}

export function documentAnnotations(state = INITIAL_STATE, action) {
    switch (action.type) {
        case documentAnnotationsConstants.SET_SHARED_ANNOTATIONS_FOR_DOCUMENTID:
            return setSharedAnnotationForDocumentId(state, action.documentId, action.payload);
        case documentAnnotationsConstants.CLEAR_SHARED_ANNOTATIONS_FOR_DOCUMENTID:
            return clearSharedAnnotationForDocumentId(state, action.documentId);
        default:
            return state;
    }
}
