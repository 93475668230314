import { binderConstants, userConstants } from '@constants/appuser/';
import { BLANK_GUID } from '../../lib/simpletools';

export var ITEM_INITIAL_STATE = {
  id:"",
  loading: true,
  error:"",

  documentId: '',
  key: '',
  position: -1,
  indentCount: 0,
  positionString: '',
  adminPositionString: '',
  itemRequiresDecision: '',
  itemPresentedBy: '',
  showItemName: null,
  date: '',
  name: '',
  fileName: '',
  timing: '',
  type: '',
  style: '',
  pageCount: 0,
  expiryDate: null,
  updateDate: '',
  size: 0,
  data: null,
  fetch: false,
  verified: null,
  verifying: false,
  isDeleted: false,
  genseckey: false,
};

function SetBinderItem(state, itemId){
  if(state[itemId] === undefined)
    state[itemId] = Object.assign({}, ITEM_INITIAL_STATE);
  state[itemId].id = itemId;
  state[itemId].loading = true;
  state[itemId].error = '';
  return state;
}

function UpdateBinderItem(state, item){
  if(state[item.id] === undefined)
    state[item.id] = Object.assign({}, ITEM_INITIAL_STATE);

  Object.keys(item).map(e => state[item.id][e] = item[e]);

  state[item.id].error = "";
  state[item.id].loading = false;

  return state;
}

function PopulateBinderItem(state, item){
  if(item.hasOwnProperty('items')){
    item.items.forEach(function(object){
      if(state[object.id] === undefined)
        state[object.id] = Object.assign({}, ITEM_INITIAL_STATE);

      if(state[object.id].documentId !== object.documentId)
        state[object.id].data = null;

      Object.keys(object).map(e => state[object.id][e] = object[e]);
      state[object.id].id = object.id;
      state[object.id].loading = false;
      state[object.id].error = '';

      //check to see if documentId blank and if so look for gensec key and use those instead
      if(object.documentId === undefined){
        try{
          var f = object.userItems.find(o => o.userId === BLANK_GUID)
          if(f){
            if(f.documentId !== undefined && f.documentId !== "")
              state[object.id].documentId = f.documentId
            if(f.key !== undefined && f.key !== "")
              state[object.id].key = f.key
            if(f.size !== undefined && f.size !== "")
              state[object.id].size = f.size
            state[object.id].genseckey = true
          }
        }catch(e){}
      }else if(state[object.id].genseckey){
        if(object.documentId !== "" && object.key !== undefined && object.key !== "" && object.size !== undefined && object.size !== 0)
          state[object.id].genseckey = false
      }
    });
  }

  return state;
}

function UpdateDocState(state, newDocState) {
  if (state[newDocState.data.oldUserItemId]) {
    state[newDocState.data.newUserItemId] = {
      ...state[newDocState.data.oldUserItemId],
      id: newDocState.data.newUserItemId,
      data: newDocState.data.newDocBlob,
      size: newDocState.data.newDocBlob.size,
      documentId: newDocState.data.newDocId,
      isSigned: true,
    };

    delete state[newDocState.data.oldUserItemId];
  }

  return state;
}

function UpdateDocumentDownload(state, documentitem){
  if(documentitem.id !== ""){
    if(state[documentitem.id] === undefined){
      state[documentitem.id] = Object.assign({}, ITEM_INITIAL_STATE);
      state[documentitem.id].loading = false;
      state[documentitem.id].id = documentitem.id;
      state[documentitem.id].error = '';
    }
    if(documentitem.hasOwnProperty('data'))
      state[documentitem.id].data = documentitem.data;
    state[documentitem.id].fetch = true;
    if(documentitem.hasOwnProperty('documentId'))
      state[documentitem.id].documentId = documentitem.documentId;
  }
  return state;
}

export function binderItems(state = {}, action) {
  switch (action.type) {
    case binderConstants.POPULATE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.item));

    // case binderConstants.DELETE_ITEM_SUCCESS:
    //   return Object.assign({}, DeleteBinderItem(state, action.itemId));
    // case binderConstants.DELETE_ITEM_FAILURE:
    //   return Object.assign({}, SetErrorBinderItem(state, action.itemId, action.error));

    case binderConstants.COMPLETE_DOWNLOAD_DOCUMENT_REQUEST:
      return Object.assign({}, UpdateDocumentDownload(state, action.documentitem));

    case binderConstants.GET_VOTEANSWER_SUCCESS:
      return Object.assign({}, UpdateBinderItem(state, {id: action.itemId, voteAnswer: action.answer }));

    case binderConstants.GET_VOTE_SUCCESS:
      return Object.assign({}, UpdateBinderItem(state, {id: action.itemId, data: action.details }));

    case binderConstants.SET_VOTE_SUCCESS:
      return Object.assign({}, UpdateBinderItem(state, {id: action.itemId, voteAnswer: action.answer, voteResponse: action.voteResponse }));

    case binderConstants.UPDATE_DOC_STATE:
      return Object.assign({}, UpdateDocState(state, action.payload));

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
