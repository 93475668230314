import { userConstants } from '@constants/appuser';
//import { userConstants, deviceConstants, boardConstants, binderConstants, customerConstants, companyConstants } from '@constants/appuser';
import {
  BLANK_GUID,
} from '@lib/simpletools';
import moment from 'moment';

//import {NamesStorage} from '../lib/indexeddb';
export var REDUCE_INITIAL = {
  loading: false,
  userLimit: undefined,
  groups: undefined,
  groupsLoading: false,
}

let userCache = JSON.parse(localStorage.getItem(window.athenaAppID));
const initialState = userCache ?
  Object.assign({ customer: {}, data:{}, person: {}})
  :
  Object.assign({customer: {}, data:{}, person: {}})

export var USER_INITIAL_STATE = {
  id:"",
  imageId: "",
  loading: true,
  error:"",

  personId: '',
  firstName: '',
  lastName: '',
  username: '',
  alias:'',
  email: '',
  mobile: '',
  companyEmployeeId: '',
  type: '',
  function: '',
  registrationCode: '',
  deviceIds: null,
  deviceIdsLastUpdate: null,
  recoveryCards: null,
  recoveryCardsLastUpdate: null,
  attendeeIds: [],
  recipientIds: [],
  settings: null,
  key: null,
  hasRegistered: false,
  isDeleted: false,
  customerId: '',
  binders: null,
  bindersLoading: false,
  isInvitedUser: false,
};

export var TASK_INITIAL = {
  jobStep: -1,
  jobStepCurrent: 0,
  jobStepTotal: 0,
  jobTodo: [],
  jobCompleted: [],
  files: false,
  binder: false,
  start: false,
  error: "",
  customerId: "",
  type: "",
  dataId: "",
  userId: "",
  dateCreated: "",
  dateCompleted: "",
  dateLocked: "",
  completedByUserId: "",
  lockedByUserId: "",
  metadata: {},
  newTask: false,
}

function setUser(state, user){
  if(state.data === undefined) state.data = {};
  state.data[user.id] = Object.assign({}, USER_INITIAL_STATE, user);
  return state;
}

function populateUsers(state, list){
  list.forEach(u => {
    state = setUser(state, u)
  })
  return state
}

export function users(state = initialState, action) {
  switch (action.type) {

    case userConstants.GET_USERS_REQUEST:
      return Object.assign({}, state);
    case userConstants.GET_USERS_SUCCESS:
      return Object.assign({}, populateUsers(state, action.list), {latestAction: new Date()});
    case userConstants.GET_USERS_FAILURE:
      return Object.assign({}, state);

    case userConstants.CHANGE_PASSWORD_INTIALISE:
      return Object.assign({}, state, { passChange: undefined })
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return Object.assign({}, state, { passChange: "loading" })
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, { passChange: "success" })
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, { passChange: "error", error: action.error })

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});

    default:
      return state
  }
}
