import { fileConstants, userConstants } from '@constants/appuser/';
import * as CrytpoLib from '@lib/cryptojs';
import { BLANK_GUID } from '@lib/simpletools';

export var FILE_INITIAL_STATE = {
  id:"",
  documentId:"",
  tag:"",
  date:"",
  updateDate:"",
  displayName:"",
  type:"",
  status:"",
  fileName:"",
  fileSize:"",
  key: "",
  error: "",
  published: false,
  fetch:false,
  loading: false,
  data: null,
  userFiles: [],
};


function SetLoadingSateImage(state, data){
  var newstate = state;
  if(newstate[data] === undefined)
    newstate[data] = Object.assign({}, FILE_INITIAL_STATE);
  newstate[data].loading = true;
  newstate[data].error = '';
  newstate[data].documentId = data;
  newstate[data].id = data;
  return newstate;
}

function PopulateFileImage(state, data){
  if(state[data.id] === undefined)
    state[data.id] = Object.assign({}, FILE_INITIAL_STATE);
  state[data.id].loading = false;
  state[data.id].fetch = true;
  state[data.id].error = '';
  if(data.image.byteLength < 1){
    state[data.id].fetch = false;
    state[data.id].error = 'no data';
  }else{
    state[data.id].data = data.image;
    state[data.id].type = 'image/png';
  }
  return state;
}

function SetErrorFile(state, Iid, error){
  if(state[Iid] === undefined){
    state[Iid] = Object.assign({}, FILE_INITIAL_STATE);
    state[Iid].id = Iid;
  }
  state[Iid].loading = false;
  state[Iid].fetch = false;
  state[Iid].error = error;
  return state;
}

function SetError(state, Iid, error){
  if(error !== undefined && Iid !== undefined && state[Iid] !== undefined)
    state[Iid].error = error;
  return state;
}

function SetInitialFileStatus(state, fileid){
  if(state[fileid] === undefined)
    state[fileid] = Object.assign({}, FILE_INITIAL_STATE);
  state[fileid].loading = true;
  state[fileid].id = fileid;
  state[fileid].error = '';
  return state;
}

function UpdateFileDownload(state, fileitem){
  if(fileitem.id !== ""){
    if(state[fileitem.id] === undefined){
      state[fileitem.id] = Object.assign({}, FILE_INITIAL_STATE);
      state[fileitem.id].loading = false;
      state[fileitem.id].id = fileitem.id;
      state[fileitem.id].error = '';
    }
    if(fileitem.hasOwnProperty('data'))
      state[fileitem.id].data = fileitem.data;
    if(fileitem.hasOwnProperty('documentId'))
      state[fileitem.id].documentId = fileitem.documentId;
    state[fileitem.id].fetch = true;
    state[fileitem.id].loading = false;
  }
  return state;
}

export function file(state = {}, action) {
  switch (action.type) {
    case fileConstants.DOWNLOAD_IMAGE_REQUEST:
      return Object.assign({}, SetLoadingSateImage(state, action.imageid));
    case fileConstants.DOWNLOAD_IMAGE_SUCCESS:
      return Object.assign({}, PopulateFileImage(state, action.payload));
    case fileConstants.DOWNLOAD_IMAGE_FAILURE:
      return Object.assign({}, SetErrorFile(state, action.imageid, action.error));

    case fileConstants.DOWNLOAD_REQUEST:
      return Object.assign({}, SetInitialFileStatus(state, action.documentId));
    case fileConstants.DOWNLOAD_SUCCESS:
      return Object.assign({}, UpdateFileDownload(state, action.file));
    case fileConstants.DOWNLOAD_FAILURE:
      return Object.assign({}, state, SetError(state, action.documentId, action.error));

    case fileConstants.COMPLETE_DOWNLOAD_FILE_REQUEST:
      return Object.assign({}, UpdateFileDownload(state, action.fileitem));

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
