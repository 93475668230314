import { binderConstants, boardConstants, userConstants } from '@constants/appuser/';
import { commonConstants, BinderStatus } from '@constants/common.constants';

export var BINDER_INITIAL_STATE = {
  id:"",
  loading: true,
  populating: false,
  hasPopulated: false,
//  processing: false,
  error:"",

  boardId: '',
  cacheId: '',
  isCached: false,
  minuteId: '',
  saving: false,
  name: '',
  meetingDate: '',
  expiryDate: '',
  creationDate: '',
  updateDate: '',
  locationName: '',
  locationLatitude: '',
  locationLongitude: '',
  itemIds: [],
  itemCount: 0,
  settings: null,
  attendees: [''],
  recipients: [''],
  invitees: [''],
  countryBlacklist: [],
  calendarId: "",
  thumbnailImageDownloadId: '',
  usageIds: [],
  binderStatus: '',
  published: false,
  deleteNotesAfterMeeting: false,
  deleteAnnotationsAfterMeeting: false,
  deleteNotesAfterDays: 0,
  deleteAnnotationsAfterDays: 0,
  isDeleted: false,
  lockUserId: '',
  lockExpired: '',
  lockType: '',
  lockProgress: 0,
  lastErrorCode: '',
  lastErrorDate: '',
  lastErrorDetail: null,
};

function setBoardBinders(state, payload){
  function setBinder(state, binder){
    let c = state[binder.id] !== undefined ? state[binder.id] : Object.assign({}, BINDER_INITIAL_STATE)
    if(c.updateDate !== binder.updateDate)
      c.hasPopulated = false
    state[binder.id] = Object.assign({}, c, binder)
    return state
  }
  payload.Archive.forEach(o => {
    state = setBinder(state, o)
  })
  payload.Current.forEach(o => {
    state = setBinder(state, o)
  })
  payload.Previous.forEach(o => {
    state = setBinder(state, o)
  })

  return state
}

function setBinders(state, binder, extra = {}){
  const item = Object.assign({}, binder, extra);
  if(state[item.id] === undefined)
    state[item.id] = Object.assign({}, BINDER_INITIAL_STATE);

  if(item.hasOwnProperty('id'))
    state[item.id].id = item.id;
  if(item.hasOwnProperty('boardId'))
    state[item.id].boardId = item.boardId;
  if(item.hasOwnProperty('name'))
    state[item.id].name = item.name;
  if(item.hasOwnProperty('binderName'))
    state[item.id].name = item.binderName;
  if(item.hasOwnProperty('meetingDate'))
    state[item.id].meetingDate = item.meetingDate;
  if(item.hasOwnProperty('expiryDate'))
    state[item.id].expiryDate = item.expiryDate;
  if(item.hasOwnProperty('creationDate'))
    state[item.id].creationDate = item.creationDate;
  if(item.hasOwnProperty('updateDate'))
    if(item.updateDate !== commonConstants.SET_NULL_DATE)
      state[item.id].updateDate = item.updateDate;
  if(item.hasOwnProperty('locationName'))
    state[item.id].locationName = item.locationName;
  else if(state[item.id].locationName === undefined)
    state[item.id].locationName = "";
  if(item.hasOwnProperty('locationLatitude'))
    state[item.id].locationLatitude = item.locationLatitude;
  if(item.hasOwnProperty('locationLongitude'))
    state[item.id].locationLongitude = item.locationLongitude;
  if(item.hasOwnProperty(BinderStatus.published))
    state[item.id].published = item.published;
  if(item.hasOwnProperty('deleteNotesAfterMeeting'))
    state[item.id].deleteNotesAfterMeeting = item.deleteNotesAfterMeeting;
  if(item.hasOwnProperty('deleteAnnotationsAfterMeeting'))
    state[item.id].deleteAnnotationsAfterMeeting = item.deleteAnnotationsAfterMeeting;
  if(item.hasOwnProperty('deleteNotesAfterDays'))
    state[item.id].deleteNotesAfterDays = item.deleteNotesAfterDays;
  if(item.hasOwnProperty('deleteAnnotationsAfterDays'))
    state[item.id].deleteAnnotationsAfterDays = item.deleteAnnotationsAfterDays;
  if(item.hasOwnProperty('itemCount'))
    state[item.id].itemCount = item.itemCount;
  if(item.hasOwnProperty('itemIds')){
    state[item.id].itemIds = item.itemIds;
    state[item.id].itemCount = item.itemIds.length;
  }
  if(item.hasOwnProperty('items')){
    state[item.id].itemIds = [];
    item.items.forEach(function(object){
      state[item.id].itemIds.push(object.id);
    });
    state[item.id].itemCount = item.items.length;
  }
  if(item.hasOwnProperty('settings'))
    state[item.id].settings = item.settings;
  if(item.hasOwnProperty('attendees'))
    state[item.id].attendees = item.attendees;
  if(item.hasOwnProperty('invitees'))
    state[item.id].invitees = item.invitees;
  if(item.hasOwnProperty('recipients'))
    state[item.id].recipients = item.recipients;

  if(item.hasOwnProperty('countryBlacklist'))
    state[item.id].countryBlacklist = item.countryBlacklist;
  if(item.hasOwnProperty('thumbnailImageDownloadId'))
    state[item.id].thumbnailImageDownloadId = item.thumbnailImageDownloadId;
  if(item.hasOwnProperty('usageIds'))
    state[item.id].usageIds = item.usageIds;
  if(item.hasOwnProperty('usage')){
    state[item.id].usageIds = [];
    item.usage.forEach(function(object){
      state[item.id].usageIds.push(object.id);
    });
  }
  if(item.hasOwnProperty('lockUserId'))
    state[item.id].lockUserId = item.lockUserId;
  if(item.hasOwnProperty('lockExpired')){
    state[item.id].lockExpired = item.lockExpired;
  }
  if(item.hasOwnProperty('lockType'))
    state[item.id].lockType = item.lockType;
  if(item.calendarId !== undefined){
    state[item.id].calendarId = item.calendarId;
  }
  if(item.hasOwnProperty('type'))
    state[item.id].binderStatus = item.type.toLowerCase();
  if(item.hasOwnProperty('binderStatus'))
    state[item.id].binderStatus = item.binderStatus.toLowerCase();
  if(item.hasOwnProperty('isDeleted'))
    state[item.id].isDeleted = item.isDeleted;
  if(item.calendarId !== undefined){
    state[item.id].calendarId = item.calendarId;
  }

  if(item.hasOwnProperty('loading'))
    state[item.id].loading = item.loading;
  if(item.hasOwnProperty('populating'))
    state[item.id].populating = item.populating;
  if(item.hasOwnProperty('hasPopulated'))
    state[item.id].hasPopulated = item.hasPopulated;
  if(item.hasOwnProperty('error'))
    state[item.id].error = item.error;


  //state[binder.id] = Object.assign({}, state[binder.id], binder, {loading: false}, extra);
  return state
}

function binderRemoval(state, boardId, binderId){
  delete state[binderId];
  return state;
}

function UpdateDocState(state, newDocState) {
  if (state[newDocState.binderId]) {
    state[newDocState.binderId].itemIds = state[newDocState.binderId].itemIds.filter(id => id !== newDocState.data.oldUserItemId);
    state[newDocState.binderId].itemIds.push(newDocState.data.newUserItemId);
  }
  return state;
}

export function binder(state = {}, action) {
  switch (action.type) {
    case boardConstants.PREVIEW_BOARDBINDER_SUCCESS:
      return Object.assign({}, setBoardBinders(state, action.payload));

    case binderConstants.POPULATE_BINDERCONTENT_REQUEST:
      return Object.assign({}, setBinders(state, {id: action.binderId, loading: true, populating: true}));
    case binderConstants.POPULATE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, setBinders(state, action.item, {hasPopulated: true, loading: false, populating: false}), {latestAction: new Date()});
    case binderConstants.POPULATE_BINDERCONTENT_FAILURE:
      return Object.assign({}, setBinders(state, {id: action.binderId, loading: true, populating: false}));

    case binderConstants.BINDER_ADDITION_SUCCESS:
    case binderConstants.BINDER_UPDATE_SUCCESS:
      action.binder.id = action.binder.binderId
      return Object.assign({}, setBinders(state, action.binder))
    case binderConstants.BINDER_REMOVAL_SUCCESS:
      return Object.assign({}, binderRemoval(state, action.boardId, action.binderId))

    case binderConstants.UPDATE_DOC_STATE:
      return Object.assign({}, UpdateDocState(state, action.payload));

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
