import { statusConstants } from '@constants/appuser/';

const initial = {
  displaySettings:{
    alwaysShowPageNumber: false,
    autoSelectLastTool: true,
    autoShowProgress: true,
    freehandNotes: true,
    pagesDisplayed: true,
    returnToCurrentPage: true,
    scrollDirection: false,
    scrollingPageTransition: false,
    showAgendaNextToContent: false,
    showProgressView: true,
    showScrubBar: false,
    showsNavigation: true,
  },
  sortSettings: {
    sortByGroupDate: false,
    sortOption: 0,
  },
  idArray: {},
  bookmarks: [],
  currentPage: [],
}

function setIdArray(state, id, value){
  if(value === 0){
    if(state.idArray[id] !== undefined)
      delete state.idArray[id]
    return state
  }

  state.idArray[id] = value
  return state
}

export function appStatus(state = initial, action) {
  switch (action.type) {
    case statusConstants.GET_STATUS_REQUEST:
      return Object.assign({}, state);
    case statusConstants.GET_STATUS_SUCCESS:
      return Object.assign({}, state, action.payload);
    case statusConstants.GET_STATUS_FAILURE:
      return Object.assign({}, state);

    case statusConstants.SET_STATUS_REQUEST:
      return Object.assign({}, state, action.payload);
    case statusConstants.SET_STATUS_SUCCESS:
      return Object.assign({}, state);
    case statusConstants.SET_STATUS_FAILURE:
      return Object.assign({}, state);

    case statusConstants.SET_BOOKMARK_REQUEST:
      return Object.assign({}, state, {bookmarks: action.bookmark, latestAction: new Date()});

    case statusConstants.SET_CURRENTPAGE_REQUEST:
      return Object.assign({}, state, {currentPage: action.currentPage, latestAction: new Date()});

    case statusConstants.SET_STATE_SUCCESS:
      return Object.assign({}, setIdArray(state, action.id, action.value), {latestAction: new Date()});
  }

  return state
}
