import { boardConstants, userConstants, binderConstants } from '@constants/appuser/';
import { BinderStatus } from '@constants/common.constants';

export var REDUCE_INITIAL = {
  boardLoading: false,
  boardIds: [],
  boards: {},
}

const BOARD_INITIAL = {
  id: "",
  name: "",
  customerId: "",
  customerName: "",
  imageId: "",
  boardSettings: null,
  countryBlacklist: [],
  calendarId: '',
  calendar: [],
  loadingCalendar: false,
  isSuspended: false,
  isComplete: false,
  updateDate: "",
  creationDate: "",
  loadingBinders: false,
  populate: false,
  archive: [],
  current: [],
  previous: [],
  files: null,
}

let userCache = JSON.parse(localStorage.getItem(window.athenaAppID))
const initialState = userCache ?
  Object.assign({}, REDUCE_INITIAL)
  :
  Object.assign({}, REDUCE_INITIAL)

function cmpCalendarItem(a, b){
  var dateA=new Date(a.startTime), dateB=new Date(b.startTime)
  return dateA-dateB //sort by date ascending
}

function setBoard(state, board, extra = {}){
  if(!state.boards) { return; }
  if(state.boards[board.id] !== undefined){
    state.boards[board.id] = Object.assign({}, state.boards[board.id], board, extra)
  }else{
    state.boards[board.id] = Object.assign({}, BOARD_INITIAL, board, extra)
  }
  return state
}

function setBoards(state, payload, extra = {}){
  let boardIds = []
  payload.forEach((board) => {
    boardIds.push(board.id)

    state = setBoard(state, board, extra)
  });

  state.boardIds = boardIds
  return state
}

function updateBoardBinders(state, boardId, binders){
  if(boardId !== ""){
    if(state.boards[boardId] === undefined){
      state.boards[boardId] = Object.assign({}, BOARD_INITIAL)
    }
    try { state.boards[boardId].archive = binders.Archive.map(o => o.id) } catch { }
    try { state.boards[boardId].current = binders.Current.map(o => o.id) } catch { }
    try { state.boards[boardId].previous = binders.Previous.map(o => o.id) } catch { }
  }

  return state
}

function binderAddition(state, binder){
  if(binder.boardId === "" || binder.boardId === undefined) return state

  if (!state.boards[binder.boardId]) { return state; }

  let list = null
  if(binder.type === BinderStatus.current)
    list = state.boards[binder.boardId].current
  if(binder.type === BinderStatus.previous)
    list = state.boards[binder.boardId].previous
  if(binder.type === BinderStatus.archive)
    list = state.boards[binder.boardId].archive

  if(list && Array.isArray(list) && list.includes(binder.binderId) === false){
    list.push(binder.binderId)
  }
  return state
}

function binderRemoval(state, boardId, binderId){
  if(boardId === "" || boardId === undefined) return state

  var p = state.boards[boardId].current.indexOf(binderId)
  if(p !== -1){
    state.boards[boardId].current.splice(p, 1);
  }
  p = state.boards[boardId].previous.indexOf(binderId)
  if(p !== -1){
    state.boards[boardId].previous.splice(p, 1);
  }
  p = state.boards[boardId].archive.indexOf(binderId)
  if(p !== -1){
    state.boards[boardId].archive.splice(p, 1);
  }

  return state
}

function updateBoard(state, obj){
  if(obj.id !== ""){
    if(state.boards[obj.id] === undefined){
      state.boards[obj.id] = Object.assign({}, BOARD_INITIAL)
    }
    state.boards[obj.id] = Object.assign(state.boards[obj.id], obj)
  }

  return state
}

function UpdateCalendar(state, boardId, list, customerId){
  //sort by date
  list.sort(cmpCalendarItem);

  if(state.boards[boardId] !== undefined && state.boards[boardId].calendar !== undefined){
    var calendar = state.boards[boardId].calendar
    list.forEach(function(item) {
      if(calendar.find(o => o.id === item.id) === undefined)
        calendar.push(item)
    })
  }

  return state;
}

export function board(state = initialState, action) {
  switch (action.type) {
    case boardConstants.PREVIEW_BOARD_REQUEST:
      return Object.assign({}, state, {boardLoading: true});
    case boardConstants.PREVIEW_BOARD_SUCCESS:
      return Object.assign({}, setBoards(state, action.payload), {boardLoading: false});
    case boardConstants.PREVIEW_BOARD_FAILURE:
      return Object.assign({}, state, {boardLoading: false});

    case boardConstants.POPULATE_BOARD_REQUEST:
      return Object.assign({}, state);
    case boardConstants.POPULATE_BOARD_SUCCESS:
      return Object.assign({}, setBoard(state, action.payload, {populate: true}), {latestAction: new Date()});
    case boardConstants.POPULATE_BOARD_FAILURE:
      return Object.assign({}, state);

    case boardConstants.PREVIEW_BOARDBINDER_REQUEST:
      return Object.assign({}, updateBoard(state, {id: action.boardId, loadingBinders: true}));
    case boardConstants.PREVIEW_BOARDBINDER_SUCCESS:
      return Object.assign({}, updateBoardBinders(state, action.boardId, action.payload));
    case boardConstants.PREVIEW_BOARDBINDER_FAILURE:
      return Object.assign({}, updateBoard(state, {id: action.boardId, loadingBinders: false}));

    case boardConstants.POPULATE_BOARDFILES_REQUEST:
      return Object.assign({}, state);
    case boardConstants.POPULATE_BOARDFILES_SUCCESS:
      return Object.assign({}, setBoard(state, {id: action.boardId, files: action.payload}), {latestAction: new Date()});
    case boardConstants.POPULATE_BOARDFILES_FAILURE:
      return Object.assign({}, state);

    case boardConstants.GET_CALENDAR_REQUEST:
      return Object.assign({}, updateBoard(state, {id: action.query.boardId, loadingCalendar:true}, action.customerId))
    case boardConstants.GET_CALENDAR_SUCCESS:
      return Object.assign({}, UpdateCalendar(state, action.boardId ,action.list, action.customerId), {latestAction: new Date()});
    case boardConstants.GET_CALENDAR_FAILURE:
      return Object.assign({}, updateBoard(state, {id: action.query.boardId, error: action.error, loadingCalendar:false}, action.customerId))

    case binderConstants.BINDER_ADDITION_SUCCESS:
      return Object.assign({}, binderAddition(state, action.binder))
    case binderConstants.BINDER_UPDATE_SUCCESS:
      state = binderRemoval(state, action.binder.boardId, action.binder.binderId)
      return Object.assign({}, binderAddition(state, action.binder))
    case binderConstants.BINDER_REMOVAL_SUCCESS:
      return Object.assign({}, binderRemoval(state, action.boardId, action.binderId))

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
