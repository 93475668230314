import { kvpConstants } from "../../constants/admin";

var INITIAL_STATE = {
    APIConnectionStatus: true,
    isOnline: true,
    itemIdHasAnnotations: []
}

export function kvp(state = INITIAL_STATE, action) {
    switch (action.type) {
        case kvpConstants.SET_KVP:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        case kvpConstants.RESET_KVP:
            return {
                ...state,
                [action.payload.key]: INITIAL_STATE.hasOwnProperty(action.payload.key) ? INITIAL_STATE[action.payload.key] : null
            }
        case kvpConstants.DELETE_KVP:
            if (state[action.payload.key]) {
                delete state[action.payload.key];
            }
            return {...state};
        case kvpConstants.CLEAR_ALL_KVP:
            return INITIAL_STATE
        default:
            return state;
    }
}
