import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { binder } from './binder.reducer';
import { binderItems } from './bitems.reducer';
import { board } from './board.reducer';
import { file } from './file.reducer';
import { uploadQueue } from './uploadqueue.reducer';
import { appStatus } from './status.reducer';
import { intlReducer } from 'react-intl-redux';
import { popovers } from '../popover.reducer';
import { marketingMessages } from '../marketing.reducer';
import { kvp } from './kvp.reducer';
import { documentAnnotations } from './documentAnnotations.reducer';
import { resolutions } from '../admin/resolutions.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  board,
  binder,
  binderItems,
  file,
  users,
  uploadQueue,
  appStatus,
  popovers,
  marketingMessages,
  keyValuePair: kvp,
  documentAnnotations,
  resolutions,
  intl: intlReducer,
});

export default rootReducer;
